import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import SubscriptionsList from './SubscriptionsList';
import DownloadPage from './DownloadPage';

const FeatureSection: React.FC<{ title: string; description: string; imageOnRight: boolean; imageSrc: string }> = ({
    title,
    description,
    imageOnRight,
    imageSrc,
}) => (
    <Paper elevation={3} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={4} alignItems="center" direction={imageOnRight ? 'row' : 'row-reverse'}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1">{description}</Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                {imageSrc.endsWith('.webm') ? (
                    <Box
                        component="video"
                        autoPlay
                        loop
                        muted
                        sx={{
                            maxWidth: '100%',
                            height: 'auto',
                        }}
                    >
                        <source src={imageSrc} type="video/webm" />
                        Your browser does not support the video tag.
                    </Box>
                ) : (
                    <Box
                        component="img"
                        sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            objectFit: 'contain',
                        }}
                        alt={title}
                        src={imageSrc}
                    />
                )}
            </Grid>
        </Grid>
    </Paper>
);

const SonaScriptLandingPage: React.FC = () => {
    const features = [
        {
            title: 'Productivity app, that is always at your fingertips',
            description:
                'SonaScript is a productivity app that allows you to transcribe your speech to text, anywhere, anytime. It also boosts your productivity utilising the power of LLMs - custom follow-up prompts allow you to translate to othel languages, format your text, fix grammar, create emails, and so much more. The olny limit is your imagination',
            imageSrc: '/usage master.webm',
        },
        {
            title: 'Flexible Transcription Options',
            description: 'Choose between high-quality online transcription using OpenAI key and local mode transcription using downloaded models (requires more RAM).',
            imageSrc: '/model.gif',
        },
        {
            title: 'Comprehensive Transcription History',
            description:
                'Your transcriptions are locally stored and accessible anytime you want. Modify the text on the fly, re-record your transcriptions, and add new details with ease. Your work is always at your fingertips, ready for review and refinement.',
            imageSrc: '/history.gif',
        },
        {
            title: 'Text Transformation',
            description: 'Transform your transcribed input using LLM models (only online mode supported, using your own OpenAI key).',
            imageSrc: 'prompt.gif',
        },
    ];

    return (
        <Container maxWidth="lg">
            <Box my={4}>
                <Typography variant="h2" component="h1" gutterBottom align="center">
                    Welcome to SonaScript
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom align="center">
                    Effortless Speech-to-Text, Anytime, Anywhere
                </Typography>

                <Box my={6}>
                    <SubscriptionsList />
                </Box>

                <Box my={6}>
                    <DownloadPage isMainPage={true} />
                </Box>

                <Box my={6}>
                    <Paper elevation={5} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
                        <Container>
                            <Typography variant="h4" component="h2" gutterBottom align="center">
                                Key Features
                            </Typography>
                            {features.map((feature, index) => (
                                <FeatureSection
                                    key={index}
                                    title={feature.title}
                                    description={feature.description}
                                    imageOnRight={index % 2 === 0}
                                    imageSrc={feature.imageSrc}
                                />
                            ))}
                        </Container>
                    </Paper>
                </Box>
            </Box>
        </Container>
    );
};

export default SonaScriptLandingPage;
