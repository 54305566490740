import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const Terms: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4} sx={{ '& .MuiListItem-root': { py: 0.5 } }}>
                {/* Main Heading */}
                <Typography variant="h4" component="h1" gutterBottom>
                    Terms of Service
                </Typography>

                {/* Last Updated Date */}
                <Typography variant="subtitle1" gutterBottom>
                    Last Updated: 26 November 2024
                </Typography>

                {/* Introduction Paragraph */}
                <Typography variant="body1" paragraph>
                    <Typography variant="h6">Welcome to Sonascript!</Typography>
                    These Terms of Service ("Terms") govern your access to and use of the Sonascript application ("App") and any related services ("Services")
                    provided by Nikita Sklyarov ("we," "us," or "our"). By downloading, accessing, or using the App, you agree to be bound by these Terms. If
                    you do not agree to these Terms, please do not use the App.
                </Typography>

                {/* Section 1: Eligibility */}
                <Typography variant="h6" gutterBottom>
                    1. Eligibility
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You must be at least 13 years old to use the App." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="If you are under 18, you may use the App only with the consent of your parent or legal guardian." />
                    </ListItem>
                </List>

                {/* Section 2: Account Registration */}
                <Typography variant="h6" gutterBottom>
                    2. Account Registration
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You need to create an account to access the App." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You agree to provide accurate and up-to-date information during registration." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You are responsible for maintaining the confidentiality of your account credentials." />
                    </ListItem>
                </List>

                {/* Section 3: Description of Services */}
                <Typography variant="h6" gutterBottom>
                    3. Description of Services
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Sonascript transcribes your speech and pastes it into the input field you are focused on." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="The App can use either the online OpenAI Whisper model or a local Whisper model for transcription." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Additional features include follow-up prompts using the OpenAI API." />
                    </ListItem>
                </List>

                {/* Section 4: User Conduct */}
                <Typography variant="h6" gutterBottom>
                    4. User Conduct
                </Typography>
                <Typography variant="body1" paragraph>
                    You agree not to:
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Use the App for any illegal or unauthorized purpose." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Modify, adapt, hack, or reverse engineer the App." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Transmit any worms, viruses, or harmful code." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Violate any laws in your jurisdiction." />
                    </ListItem>
                </List>

                {/* Section 5: User Content */}
                <Typography variant="h6" gutterBottom>
                    5. User Content
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Any content you create using the App remains your property." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="By using the App, you grant us a non-exclusive license to use your content solely to provide and improve the Services." />
                    </ListItem>
                </List>

                {/* Section 6: Privacy Policy */}
                <Typography variant="h6" gutterBottom>
                    6. Privacy Policy
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Your use of the App is also governed by our Privacy Policy, which is incorporated into these Terms by reference." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Please review the Privacy Policy to understand how we collect, use, and disclose your personal information." />
                    </ListItem>
                </List>

                {/* Section 7: Intellectual Property */}
                <Typography variant="h6" gutterBottom>
                    7. Intellectual Property
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="All rights, titles, and interests in and to the App are owned by us or our licensors." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You are granted a limited, non-exclusive, non-transferable license to use the App in accordance with these Terms." />
                    </ListItem>
                </List>

                {/* Section 8: Third-Party Services */}
                <Typography variant="h6" gutterBottom>
                    8. Third-Party Services
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="The App may use third-party services like the OpenAI Whisper model." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="We are not responsible for any third-party services and do not guarantee their availability." />
                    </ListItem>
                </List>

                {/* Section 9: Disclaimer of Warranties */}
                <Typography variant="h6" gutterBottom>
                    9. Disclaimer of Warranties
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary='The App is provided "as is" and "as available" without warranties of any kind.' />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="We do not warrant that the App will be error-free or uninterrupted." />
                    </ListItem>
                </List>

                {/* Section 10: Limitation of Liability */}
                <Typography variant="h6" gutterBottom>
                    10. Limitation of Liability
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, or consequential damages arising out of your use of the App." />
                    </ListItem>
                </List>

                {/* Section 11: Indemnification */}
                <Typography variant="h6" gutterBottom>
                    11. Indemnification
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="You agree to indemnify and hold us harmless from any claims arising out of your violation of these Terms." />
                    </ListItem>
                </List>

                {/* Section 12: Termination */}
                <Typography variant="h6" gutterBottom>
                    12. Termination
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="We reserve the right to suspend or terminate your access to the App at our sole discretion." />
                    </ListItem>
                </List>

                {/* Section 13: Changes to the Terms */}
                <Typography variant="h6" gutterBottom>
                    13. Changes to the Terms
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="We may modify these Terms at any time." />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary='We will notify you of any changes by updating the "Last Updated" date.' />
                    </ListItem>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="Your continued use of the App constitutes acceptance of the new Terms." />
                    </ListItem>
                </List>

                {/* Section 14: Governing Law */}
                <Typography variant="h6" gutterBottom>
                    14. Governing Law
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText primary="These Terms are governed by and construed in accordance with the laws of Germany, without regard to its conflict of law principles." />
                    </ListItem>
                </List>

                {/* Section 15: Contact Us */}
                <Typography variant="h6" gutterBottom>
                    15. Contact Us
                </Typography>
                <List disablePadding>
                    <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'flex-start' }}>
                        <Typography sx={{ mr: 1 }}>•</Typography>
                        <ListItemText
                            primary={
                                <>
                                    If you have any questions about these Terms, please contact us at:
                                    <br />
                                    • Email: <StyledLink href="mailto:sonascript@gmail.com">sonascript@gmail.com</StyledLink>
                                    <br />
                                    • Website: <StyledLink href="https://sonascript.com">sonascript.com</StyledLink>
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </Box>
        </Container>
    );
};

export default Terms;
