import { FirebaseApp } from 'firebase/app';
import { getAuth, User } from 'firebase/auth';
import { collection, getFirestore, onSnapshot, query, where, Timestamp, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import axios from 'axios';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const initFirebase = () => {
    return app;
};

interface FirebaseFunctionParams {
    functionUrl: string;
    user: User | null;
    params?: any;
    body?: any;
    method?: string;
}

export const callFirebaseFunction = async ({ functionUrl, user = null, params = '', body = '', method = 'get' }: FirebaseFunctionParams) => {
    let axiosConfig: any = {
        method,
        url: functionUrl,
    };

    // Add authorization header if user is provided
    if (user) {
        const token = await user.getIdToken();
        axiosConfig.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    // Add optional parameters
    if (params) {
        axiosConfig.params = params;
    }
    if (body) {
        axiosConfig.data = body;
    }

    // console.log('axiosConfig', axiosConfig);

    try {
        const response = await axios(axiosConfig);
        // console.log('response', response);
        return response.data;
    } catch (err) {
        console.error('Error calling firebase function', err);
        throw err;
    }
};
