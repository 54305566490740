// AuthProvider.tsx
import React, { createContext, useEffect, useState, ReactNode } from 'react';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    User,
    Auth,
    getAuth,
    UserCredential,
    GoogleAuthProvider,
    signInWithPopup,
    OAuthProvider,
} from 'firebase/auth';
import { RemoveDeviceResponse, SubscriptionResponse } from '../types/types';
// import { collection, getFirestore, onSnapshot, query, where, Timestamp, getDocs } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { FirebaseApp } from 'firebase/app';
import { callFirebaseFunction, initFirebase } from '../services/firebaseService';

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

interface AuthContextType {
    user: User | null;
    loading: boolean;
    couponCode: string | null;
    setCouponCode: (code: string | null) => void;
    createUser: (email: string, password: string) => Promise<UserCredential>;
    signInWithGoogle: () => Promise<UserCredential>;
    signInWithApple: () => Promise<UserCredential>;
    logOut: () => Promise<void>;
    getSubscriptionStatus: (app: FirebaseApp) => Promise<SubscriptionResponse>;
    removeRegisteredDevice: (app: FirebaseApp, deviceId: string) => Promise<RemoveDeviceResponse>;
    app: FirebaseApp;
    auth: Auth;
    userCurrency: 'eur' | 'usd';
    prices: any[];
    loadingPrices: boolean;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [couponCode, setCouponCode] = useState<string | null>(null);
    const [prices, setPrices] = React.useState<any[]>([]);
    const [userCurrency, setUserCurrency] = React.useState<'eur' | 'usd'>('eur');
    const [loadingPrices, setLoadingPrices] = React.useState(true);

    const app = initFirebase();
    const auth = getAuth(app);

    const createUser = async (email: string, password: string) => {
        setLoading(true);
        try {
            return await createUserWithEmailAndPassword(auth as Auth, email, password);
        } finally {
            setLoading(false);
        }
    };

    const signInWithGoogle = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, googleProvider);
            console.log('signInWithGoogle result', result);
            console.log('signInWithGoogle couponCode', couponCode);
            setUser(result.user);
            setLoading(false);
            return result;
        } finally {
            setLoading(false);
        }
    };

    const signInWithApple = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, appleProvider);
            setUser(result.user);
            setLoading(false);
            return result;
        } finally {
            setLoading(false);
        }
    };

    const logOut = async () => {
        setLoading(true);
        try {
            await signOut(auth as Auth);
        } finally {
            setLoading(false);
        }
    };

    const getSubscriptionStatus = async (app: FirebaseApp): Promise<SubscriptionResponse> => {
        const auth = getAuth(app);
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('User not logged in');

        const response: any = await callFirebaseFunction({
            functionUrl: process.env.REACT_APP_GET_SUBSCRIPTION_USAGE_FUNCTION!,
            user: auth.currentUser,
        });

        // console.log('getSubscription result ', JSON.stringify(response));
        return response as SubscriptionResponse;
    };

    const removeRegisteredDevice = async (app: FirebaseApp, deviceId: string) => {
        const auth = getAuth(app);
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('User not logged in');
        const response: any = await callFirebaseFunction({
            functionUrl: process.env.REACT_APP_REMOVE_REGISTERED_DEVICE_FUNCTION!,
            user: auth.currentUser,
            params: { device_id: deviceId },
        });
        // console.log('remove registered device result ', JSON.stringify(response));
        return response;
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth as Auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    useEffect(() => {
        if (prices.length > 0) return;

        const controller = new AbortController();
        let isMounted = true;

        const fetchPricesAndLocation = async () => {
            if (!app) return;

            try {
                // Get user location
                const API_KEY = process.env.REACT_APP_IPGEOLOCATION_API_KEY;
                const geoResponse = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}`, { signal: controller.signal });

                if (!isMounted) return;
                const geoData = await geoResponse.json();

                // EU countries list
                const euCountries = [
                    'AT',
                    'BE',
                    'BG',
                    'HR',
                    'CY',
                    'CZ',
                    'DK',
                    'EE',
                    'FI',
                    'FR',
                    'DE',
                    'GR',
                    'HU',
                    'IE',
                    'IT',
                    'LV',
                    'LT',
                    'LU',
                    'MT',
                    'NL',
                    'PL',
                    'PT',
                    'RO',
                    'SK',
                    'SI',
                    'ES',
                    'SE',
                ];

                // Set currency based on country
                const currency = euCountries.includes(geoData.country_code2) ? 'eur' : 'usd';
                if (isMounted) setUserCurrency(currency);

                // Fetch prices from your Cloud Function
                const response: any = await callFirebaseFunction({
                    functionUrl: process.env.REACT_APP_GET_PRODUCT_PRICES_FUNCTION!,
                    params: { product_id: process.env.REACT_APP_COFFEE_SUBSCRIPTION_PRODUCT_ID! },
                    user: null,
                });

                if (isMounted) {
                    const pricesData = (response as any).prices;
                    setPrices(pricesData);
                }
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') return;
                console.error('Error fetching prices:', error);
            } finally {
                if (isMounted) setLoadingPrices(false);
            }
        };

        fetchPricesAndLocation();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [app]);

    const authValue = {
        user,
        loading,
        couponCode,
        setCouponCode,
        createUser,
        signInWithGoogle,
        signInWithApple,
        logOut,
        getSubscriptionStatus,
        removeRegisteredDevice,
        app,
        auth,
        userCurrency,
        prices,
        loadingPrices,
    };

    return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthProvider;
