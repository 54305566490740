import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Container, Grid, Paper, Skeleton, Card, CardContent, CardHeader, Button, Typography, CircularProgress } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { SubscriptionResponse } from '../types/types';
import axios from 'axios';
import { callFirebaseFunction } from '../services/firebaseService';

const SubscriptionsList: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { user, getSubscriptionStatus, app, setCouponCode, couponCode, userCurrency, prices, loadingPrices } = authContext || {};
    const [subscription, setSubscription] = React.useState<SubscriptionResponse | null>(null);
    const [loadingSubscription, setLoadingSubscription] = React.useState(false);
    const [searchParams] = useSearchParams();
    const coupon = searchParams.get('coupon');

    useEffect(() => {
        if (coupon && setCouponCode) {
            setCouponCode(coupon);
        }
    }, [coupon, setCouponCode]);

    useEffect(() => {
        if (user && getSubscriptionStatus && app) {
            setLoadingSubscription(true);
            getSubscriptionStatus(app).then((subscriptionData) => {
                // console.log('subscription SubscriptionsList:');
                // console.log(subscriptionData);
                setSubscription(subscriptionData);
                setLoadingSubscription(false);
            });
        } else {
            setLoadingSubscription(false);
            setSubscription(null);
        }
    }, [user, getSubscriptionStatus, app]);

    const getCurrentPrice = () => {
        const price = prices?.find((p) => p.currency === userCurrency);
        if (!price) return null;

        const amount = price.unit_amount / 100; // Convert from cents
        const symbol = userCurrency === 'eur' ? '€' : '$';

        return {
            amount,
            symbol,
            priceId: price.id,
            formattedPrice: `${symbol}${amount}/month`,
        };
    };

    const defaultPlanOptions = React.useMemo(() => {
        const price = getCurrentPrice();
        if (!price) return [];

        return [
            {
                id: 0,
                name: 'Coffee Subscription',
                price: price.formattedPrice,
                description:
                    "Get all features all the time and keep us buzzing with our coffee subscription. With this plan, SonaScript will be available for you 24/7. You're brew-tiful!",
                priceId: price.priceId,
                promotionCode: undefined,
                highlight: false,
            },
            {
                id: 1,
                name: 'Continue with Trial',
                price: `${price.symbol}0.00/month`, // Updated to include currency symbol
                description:
                    'Full featured trial with no expiration date and no credit card required. First 72 hours SonaScript is fully functional, after that without its morning coffee, SonaScript can only work for 2 hours before needing to rest.',
                promotionCode: undefined,
                highlight: false,
            },
        ];
    }, [prices, userCurrency]);

    const helloWhisperPlanOption = React.useMemo(() => {
        const price = getCurrentPrice();
        if (!price) return null;

        return {
            id: 0,
            name: 'Welcome From Whisper to ChatGPT',
            price: `First month free, then ${price.formattedPrice}`,
            description: `Special offer for users of Whisper to ChatGPT. First month free, then ${price.formattedPrice}. Get all features all the time and keep us buzzing with our coffee subscription. With this plan, SonaScript will be available for you 24/7. You're brew-tiful!`,
            priceId: price.priceId,
            promotionCode: process.env.REACT_APP_STRIPE_PROMOTION_CODE_ID!,
            highlight: true,
        };
    }, [prices, userCurrency]);

    const planOptions = React.useMemo(() => {
        if (couponCode?.toLowerCase() === process.env.REACT_APP_STRIPE_PROMOTION_CODE?.toLowerCase()) {
            return [helloWhisperPlanOption, defaultPlanOptions[1]].filter(Boolean);
        }
        return defaultPlanOptions;
    }, [couponCode, helloWhisperPlanOption, defaultPlanOptions]);

    if (loadingPrices) {
        return (
            <Paper elevation={5} sx={{ p: 3, my: 4, display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    return (
        <>
            <Paper elevation={5} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
                <Container>
                    {loadingSubscription ? (
                        <Grid container spacing={4} justifyContent="center">
                            {[0, 1].map((item) => (
                                <Grid item xs={12} sm={6} key={item}>
                                    <Skeleton variant="rectangular" height={200} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : user &&
                      subscription &&
                      subscription.subscription_data &&
                      subscription.subscription_data.status === 'active' &&
                      !subscription.is_trial ? (
                        <Container>
                            <Typography variant="h4" component="h2" gutterBottom align="center">
                                Your Subscription
                            </Typography>
                            <Grid container justifyContent="center" textAlign={'center'}>
                                <Card>
                                    <CardHeader
                                        title={`Subscription Plan: ${subscription.subscription_data.plan}`}
                                        subheader={`Devices: ${subscription.device_count} / ${subscription.device_limit}`}
                                    />
                                    <CardContent>
                                        <Button component={RouterLink} to="/profile" sx={{ mr: 2 }}>
                                            Go to Profile
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Container>
                    ) : (
                        <Container>
                            <Typography variant="h4" component="h2" gutterBottom align="center">
                                Choose Your Plan
                            </Typography>
                            <Grid container spacing={4} justifyContent="center">
                                {planOptions.map(
                                    (plan) =>
                                        plan && (
                                            <Grid item xs={12} sm={6} key={plan.id}>
                                                <SubscriptionCard
                                                    name={plan.name}
                                                    price={plan.price}
                                                    description={plan.description}
                                                    priceId={plan?.priceId}
                                                    promotionCode={plan?.promotionCode}
                                                    highlight={plan.highlight}
                                                />
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Container>
                    )}
                </Container>
            </Paper>
        </>
    );
};

export default SubscriptionsList;
