import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Release {
    tag_name: string;
    assets: Array<{
        name: string;
        browser_download_url: string;
    }>;
}

const DownloadPage: React.FC<{ isMainPage?: boolean }> = ({ isMainPage = true }) => {
    const [latestVersion, setLatestVersion] = useState<string>('');
    const [downloadUrls, setDownloadUrls] = useState<{
        intel: string;
        arm64: string;
    }>({ intel: '', arm64: '' });

    useEffect(() => {
        const fetchLatestRelease = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/sonascript-app/sonascript-releases/releases/latest');
                const data: Release = await response.json();

                setLatestVersion(data.tag_name);

                // Find download URLs for both architectures
                const intelAsset = data.assets.find((asset) => asset.name.endsWith('.dmg') && !asset.name.includes('arm64'));
                const armAsset = data.assets.find((asset) => asset.name.includes('arm64') && asset.name.endsWith('.dmg'));

                setDownloadUrls({
                    intel: intelAsset?.browser_download_url || '',
                    arm64: armAsset?.browser_download_url || '',
                });
            } catch (error) {
                console.error('Error fetching release info:', error);
            }
        };

        fetchLatestRelease();
    }, []);

    const handleDownload = (url: string) => {
        if (!url) return;
        window.location.href = url;
    };

    return (
        <Paper elevation={5} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column', maxWidth: isMainPage ? '100%' : '600px', mx: 'auto' }}>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    {/* <Paper elevation={5} sx={{ width: '100%', maxWidth: 600, p: 3 }}> */}
                        <Typography variant="h4" component="h2" gutterBottom align="center">
                            Download Sonascript
                        </Typography>

                        <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                            Version {latestVersion}
                        </Typography>
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexDirection: { xs: 'column', sm: 'row' },
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    onClick={() => handleDownload(downloadUrls.arm64)}
                                    disabled={!downloadUrls.arm64}
                                    sx={{
                                        height: '80px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 1,
                                        m: 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <AppleIcon sx={{ mt: 0.5 }} fontSize="large" />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                mt: 1,
                                            }}
                                        >
                                            <Typography variant="button" sx={{ fontSize: '1.1rem', lineHeight: 1.2 }}>
                                                Download for Mac
                                            </Typography>
                                            <Typography sx={{ textAlign: 'center', width: '100%' }}>Apple Silicon</Typography>
                                        </Box>
                                    </Box>
                                </Button>

                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    onClick={() => handleDownload(downloadUrls.intel)}
                                    disabled={!downloadUrls.intel}
                                    sx={{
                                        height: '80px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 1,
                                        m: 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <AppleIcon sx={{ mt: 0.5 }} fontSize="large" />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                mt: 1,
                                            }}
                                        >
                                            <Typography variant="button" sx={{ fontSize: '1.1rem', lineHeight: 1.2 }}>
                                                Download for Mac
                                            </Typography>
                                            <Typography sx={{ textAlign: 'center', width: '100%' }}>Intel</Typography>
                                        </Box>
                                    </Box>
                                </Button>
                            </Box>

                            <Button
                                fullWidth
                                variant="outlined"
                                size="large"
                                disabled
                                sx={{
                                    py: 2,
                                    fontSize: '1.1rem',
                                    display: 'flex',
                                    gap: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    m: 0,
                                }}
                            >
                                <MicrosoftIcon fontSize="large" />
                                <Typography variant="button" sx={{ fontSize: '1.1rem', lineHeight: 1.2 }}>
                                    Windows Version (Coming Soon)
                                </Typography>
                            </Button>
                        </Stack>
                    {/* </Paper> */}
                </Box>
            </Container>
        </Paper>
    );
};

export default DownloadPage;
